import { graphql, Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const BlogIndexPage = ({ location, data: { allContentfulBlogPost } }) => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO pathname={location.pathname} title={intl.formatMessage({ id: 'blog-page-title' })} />

      <div className="relative w-11/12 max-w-screen-md mx-auto py-24">
        <h1 className="font-bold text-2xl text-center mb-24">{intl.formatMessage({ id: 'blog-page-title' })}</h1>

        <section>
          {allContentfulBlogPost.edges.map(({ node: post }) => (
            <article className="flex flex-col flex-nowrap px-9 py-6 bg-white bg-opacity-10 mb-12" key={post.slug}>
              <h2 className="font-bold text-sm mb-2 hover:text-red transition-colors duration-300">
                <Link to={`/${intl.locale}/blog/${post.slug}`}>{post.title}</Link>
              </h2>

              <p className="text-sm opacity-70">{post.content?.childMarkdownRemark.excerpt}</p>

              <Link to={`/${intl.locale}/blog/${post.slug}`} className="self-end text-sm text-red">
                {intl.formatMessage({ id: 'post-read-more' })}
              </Link>
            </article>
          ))}
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogIndexQuery($locale: String) {
    allContentfulBlogPost(filter: { node_locale: { eq: $locale } }, sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          slug
          title
          content {
            childMarkdownRemark {
              excerpt(format: PLAIN, pruneLength: 260, truncate: true)
            }
          }
        }
      }
    }
  }
`;

export default BlogIndexPage;
